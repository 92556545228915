globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"4d0a17b4e1a64b4f7ffcb3b0e216e38195362e23"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

Sentry.init({
  debug: false,
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  enabled: process.env.VERCEL_ENV === "production",
  environment: process.env.VERCEL_ENV || process.env.NODE_ENV,
  ignoreErrors: [
    /.*Unauthorized.*/,
    /** The two errors below are caused by google translate browser extension. happens mostly on Microsoft Edge browser
     * details: https://github.com/facebook/react/issues/17256
     */
    /.*Failed to execute 'removeChild' on 'Node'.*/,
    /.*Failed to execute 'insertBefore' on 'Node'.*/,
    /** The next 3 errors seem to be caused by email links being scanned by Outlook
     * https://github.com/getsentry/sentry-javascript/issues/3440
     */
    /.*Non-Error promise rejection captured with value.*/,
    /.*Non-Error exception captured with keys.*/,
    /.*Event `Event` \(type=error\) captured as exception.*/,
  ],
  integrations: [
    Sentry.thirdPartyErrorFilterIntegration({
      filterKeys: ["10x-site-sentry-application-key"],
      behaviour: "drop-error-if-exclusively-contains-third-party-frames",
    }),
    Sentry.replayIntegration({
      blockAllMedia: true,
    }),
    Sentry.extraErrorDataIntegration(),
    Sentry.rewriteFramesIntegration({
      iteratee: (frame) => {
        frame.filename = frame.filename!.replace(
          process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR as string,
          "app:///",
        );
        frame.filename = frame.filename.replace(".next", "_next");
        return frame;
      },
    }),
  ],
  release: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || "unknown",
  replaysOnErrorSampleRate: 0.1,
  replaysSessionSampleRate: 0,
  tracesSampleRate: 0.04,
});
